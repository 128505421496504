<template>
  <wrapper-with-close-btn :preventNavigation="true" class="service-request" @back="$emit('close')">
    <hs-heading
      v-show="deviceForTitle"
      :title="`Provide Us With Details for ${deviceForTitle}`"
      class="service-request__title"
    />
    <div class="service-request__row">
      <div class="service-request__col service-request__col--icon">
        <DeviceIcon
          v-show="productLineName"
          :icon="productLineName"
          class="service-request__device-icon"
        />
      </div>
      <div class="service-request__col">
        <div class="service-request__title">
          Which of the following issues are you having? (Please select one.)
        </div>
        <div class="service-request__options">
          <div
            class="service-request__option"
            v-for="issueItem in issues"
            :key="issueItem.issueCode"
          >
            <hs-radio name="issueType" @change="setType" :value="issueItem.issueCode">
              {{ issueItem.issue }}
            </hs-radio>
          </div>
        </div>
      </div>
    </div>
    <StepNavigationButtons
      :has-back-button="false"
      confirm-button-text="Next"
      :disabled="disableNextStep"
      :mobile-button-width="268"
      @confirm="next"
    />
  </wrapper-with-close-btn>
</template>

<script>
import '@/assets/styles/service-request.scss';
import DeviceIcon from '@/components/shared/DeviceIcon';
import StepNavigationButtons from '@/components/shared/StepNavigationButtons';
import {mapState, mapGetters} from 'vuex';

export default {
  components: {
    DeviceIcon,
    StepNavigationButtons,
  },
  data() {
    return {
      selectedIssue: '',
    };
  },
  computed: {
    ...mapGetters('requestService', ['deviceForTitle']),
    ...mapState('deviceInformation', ['productLineName']),
    ...mapState('requestService', ['type', 'issues']),
    disableNextStep() {
      return !this.selectedIssue;
    },
  },
  methods: {
    setType(e) {
      this.selectedIssue = e.value;
    },
    next() {
      this.$store.dispatch('requestService/SET_ISSUE', this.selectedIssue);
      this.$emit('next', this.selectedIssue);
    },
  },
};
</script>
