<template>
  <div class="create-service-request">
    <select-issue-category
      v-if="step === 0"
      @next="selectCategory"
      @close="showCancellationPopup"
    />
    <select-issue-type v-if="step === 1" @next="selectType" @close="showCancellationPopup" />
    <triage
      v-if="step === 2"
      @next="step = 3"
      @uncovered="uncovered"
      @close="showCancellationPopup"
    />
    <add-details
      v-if="step === 3"
      :issueTitle="issueTitle"
      :issueIcon="issueIcon"
      @uncovered="uncovered"
      :issueCategory="issueCategory"
      @next="showPolicy"
      @close="showCancellationPopup"
      :issueType="issueType"
    />
    <confirm-address
      v-if="step === 4"
      @next="showInfo"
      @back="step = 3"
      @close="showCancellationPopup"
    />
    <add-additional-info v-if="step === 5" @next="showOptions" @close="showCancellationPopup" />
    <claim-options v-if="step === 6" @next="gotoOption" @close="showCancellationPopup" />

    <CancellationPopup
      v-if="isCancellationPopupVisible"
      v-bind="cancellationPopupData.claim"
      @hide="hideCancellationPopup"
      @confirm="leave"
    />
    <CancellationPopup
      v-if="isUncoveredPopupVisible"
      v-bind="cancellationPopupData.issueNotCovered"
      @hide="leave"
      @confirm="leave"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex';
import fetchContractProductsMixin from '@/mixins/fetchContractProductsMixin';
import SelectIssueCategory from '@/components/service-request/SelectIssueCategory';
import SelectIssueType from '@/components/service-request/SelectIssueType';
import Triage from '@/components/service-request/Triage';
import AddDetails from '@/components/service-request/AddDetails';
import ConfirmAddress from '@/components/service-request/ConfirmAddress';
import AddAdditionalInfo from '@/components/service-request/AddAdditionalInfo';
import ClaimOptions from '@/components/service-request/Options';
import cancellationPopupData from '@/constants/service-request/cancellation_popup_data';
import CancellationPopup from '@/components/shared/CancellationPopup';

export default {
  components: {
    SelectIssueCategory,
    SelectIssueType,
    Triage,
    AddDetails,
    ConfirmAddress,
    AddAdditionalInfo,
    ClaimOptions,
    CancellationPopup,
  },
  mixins: [fetchContractProductsMixin],
  data() {
    return {
      issueIcon: '',
      step: 0,
      issueCategory: '',
      issueType: '',
      isCancellationPopupVisible: false,
      isUncoveredPopupVisible: false,
    };
  },
  computed: {
    ...mapState('deviceInformation', ['equipmentName']),
    cancellationPopupData() {
      return cancellationPopupData;
    },
    issueTitle() {
      return `We Need More Info for ${this.equipmentName}`;
    },
  },
  methods: {
    showCancellationPopup() {
      this.isCancellationPopupVisible = true;
    },
    hideCancellationPopup() {
      this.isCancellationPopupVisible = false;
    },
    uncovered() {
      this.isUncoveredPopupVisible = true;
    },
    leave() {
      const {query} = this.$route;
      this.$router.push({
        name: 'MyDevices',
        query,
      });
    },
    selectCategory(issueCategory) {
      this.step = 1;
      this.issueCategory = issueCategory;
    },
    selectType(issueType) {
      this.step = 2;
      this.issueType = issueType;
    },
    showPolicy({description, time, place}) {
      this.step = 4;
    },
    showOptions(confirmation) {
      this.step = 6;
    },
    showInfo(confirmation) {
      if (confirmation) {
        this.step = 6;
      } else {
        this.step = 5;
      }
    },
    gotoOption(option) {
      const {params, query} = this.$route;
      const {id} = params;
      this.$router.push({
        name: 'StartSpecificClaim',
        params: {id, type: option.toLowerCase()},
        query: {...query, step: 'explanation'},
      });
    },
  },
  async created() {
    const {id} = this.$route.params;
    const res = await this.$store.dispatch('requestService/GET_DEVICE_DETAILS', {
      id,
    });
  },
};
</script>

<style scoped lang="scss">
.create-service-request {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>
