<template>
  <wrapper-with-close-btn
    :preventNavigation="true"
    class="service-request service-request--center-mobile"
    @back="$emit('close')"
  >
    <hs-heading
      v-show="deviceForTitle"
      :title="`Provide Us With Details for ${deviceForTitle}`"
      class="service-request__title"
    />
    <div
      class="service-request__row service-request__row--margin-on-desktop"
      :class="!showOptions ? 'service-request__row--center-mobile' : ''"
    >
      <div class="service-request__col service-request__col--icon">
        <DeviceIcon
          v-show="productLineName"
          :icon="productLineName"
          class="service-request__device-icon"
        />
      </div>
      <div class="service-request__col" v-if="currentQuestion">
        <div class="service-request__title">{{ currentQuestion.triageQuestion }}</div>
        <template v-if="showOptions">
          <div
            class="service-request__options service-request__options--no-sticky-footer"
            v-if="currentQuestion"
          >
            <div
              class="service-request__option"
              v-for="option in currentQuestion.triageAnswers"
              :key="option.triageAnswerCode"
            >
              <hs-radio
                name="triageOption"
                @change="selectOption(option)"
                :value="option.triageAnswerCode"
              >
                {{ option.triageAnswer }}
              </hs-radio>
            </div>
          </div>
        </template>
      </div>
      <div v-else class="service-request__col">
        <div class="service-request__title">{{ error }}</div>
      </div>
    </div>

    <StepNavigationButtons
      v-if="!showOptions && currentQuestion"
      :has-back-button="currentQuestion.triageAnswers.length > 1"
      :confirm-button-text="currentQuestion.triageAnswers[0].triageAnswer"
      :back-button-text="currentQuestionBackButtonText"
      @back="selectOption(currentQuestion.triageAnswers[1])"
      @confirm="selectOption(currentQuestion.triageAnswers[0])"
      :mobile-button-width="308"
    />
  </wrapper-with-close-btn>
</template>

<script>
import '@/assets/styles/service-request.scss';
import DeviceIcon from '@/components/shared/DeviceIcon';
import StepNavigationButtons from '@/components/shared/StepNavigationButtons';
import {mapState, mapGetters} from 'vuex';

export default {
  components: {
    DeviceIcon,
    StepNavigationButtons,
  },
  data() {
    return {
      issueTriageCode: '',
      questions: [],
      answers: [],
      currentQuestionIdx: 0,
      currentQuestionAnswerCode: '',
      error: '',
    };
  },
  computed: {
    ...mapGetters('requestService', ['deviceForTitle']),
    ...mapState('deviceInformation', ['productLineName']),
    ...mapState('requestService', ['productLine', 'brandCode', 'productCode', 'issue']),
    currentQuestion() {
      return this.questions[this.currentQuestionIdx];
    },
    showOptions() {
      return this.currentQuestion?.triageAnswers?.length > 2;
    },
    currentQuestionBackButtonText() {
      return this.currentQuestion.triageAnswers?.[1]?.triageAnswer;
    },
  },
  methods: {
    async selectOption(option) {
      this.answers.push(option.triageAnswerCode);
      if (option.hasNextQuestion) {
        // next question
        this.currentQuestionIdx++;
      } else if (option.triageStatus === 'unresolved') {
        // issue is unresolved, proceed with claim initiation
        const res = await this.$store.dispatch('requestService/CHECK_ISSUES_UNDER_PLAN');
        const issueIsCovered =
          res?.data?.response?.checkIssuesUnderPlanResponse?.issues[0]?.coveredUnderPlan;
        if (issueIsCovered && (issueIsCovered === 'true' || issueIsCovered === true)) {
          await this.$store.dispatch('requestService/SAVE_TRIAGE_DETAILS', {
            issueCode: this.issue,
            issueTriageCode: this.issueTriageCode,
            triageAnswers: this.answers,
          });
          this.$emit('next');
        } else {
          this.$emit('uncovered');
        }
      } else {
        // issue is resolved, show info message
        const {query} = this.$route;
        this.$router.push({
          name: 'ClaimResolved',
          query,
        });
      }
    },
    async proceed(answer) {
      const {issueCategory, issueType} = this;
      const resp = await this.$store.dispatch('requestService/TRIAGE', {
        issueCategory,
        issueType,
        id: this.questionId,
        answer,
      });
      if (resp.data?.resolved) {
        // show resolved
        const {query} = this.$route;
        this.$router.push({
          name: 'ClaimResolved',
          query,
        });
      } else if (resp.data?.next) {
        // next question
        this.question = resp.next;
      } else {
        // proceed with a claim
        this.$emit('next');
      }
    },
    finish() {
      this.$emit('next');
    },
  },
  async created() {
    const {productLine, brandCode, productCode, issue} = this;
    const resp = await this.$store.dispatch('requestService/GET_TRIAGE_DETAILS', {
      productSubCategoryCode: productLine,
      brandCode,
      productCode,
      issueCodes: [issue],
    });
    if (resp?.data?.response?.getTriageDetailsResponse?.errorDetails?.errorDetail) {
      // TODO: there can be no triage questions
      console.warn(
        resp?.data?.response?.getTriageDetailsResponse?.errorDetails?.errorDetail?.errorDescription
      );
      this.finish();
    } else {
      this.questions =
        resp?.data?.response?.getTriageDetailsResponse?.issueDetails[0]?.triageDetails;
      this.issueTriageCode =
        resp?.data?.response?.getTriageDetailsResponse?.issueDetails[0]?.issueTriageCode;

      // there is no triage error, but there aren't any questions
      // or all questions are just empty objects
      if (
        !this.questions.length ||
        this.questions.every((question) => !Object.keys(question).length)
      ) {
        this.finish();
      }
    }
  },
};
</script>
