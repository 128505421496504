<template>
  <wrapper-with-close-btn :preventNavigation="true" class="service-request" @back="$emit('close')">
    <form @submit.stop.prevent="next">
      <hs-heading
        v-show="deviceForTitle"
        :title="`We Need More Info for ${deviceForTitle}`"
        class="service-request__title"
      />
      <div class="service-request__row service-request__row--less-margin-on-mobile">
        <div class="service-request__col service-request__col--icon">
          <DeviceIcon
            v-show="productLineName"
            :icon="productLineName"
            class="service-request__device-icon"
          />
        </div>
        <div class="service-request__col">
          <div class="service-request__title service-request__title--smaller-on-mobile">
            Let us know more about the issue
          </div>
          <div
            class="service-request__row service-request__row--column-on-mobile service-request__row--no-margin-on-mobile"
          >
            <div class="service-request__col">
              <hs-label
                class="service-request__label service-request__label--less-margin-on-mobile"
                for="issueDescription"
              >
                Please describe your issue in detail
              </hs-label>
              <hs-textarea
                aria-labelledby="#issueDescription"
                id="issueDescription"
                :rows="8"
                class="details__field"
                v-model="description"
                placeholder="Please tell us more about the nature of the issue, how and where it happened"
              />
            </div>
            <div class="service-request__col">
              <hs-label class="service-request__label" for="issueDate">
                When did the issue happen?
              </hs-label>
              <hs-datepicker
                :placeholder="'MM/DD/YYYY'"
                :start-calendar-from="contractStartDate"
                aria-labelledby="#issueDate"
                calendar-type="past"
                id="issueDate"
                class="details__field"
                apply-button-text="SAVE"
                v-model="time"
              />
            </div>
          </div>
        </div>
      </div>

      <StepNavigationButtons
        :has-back-button="false"
        :disabled="disableNextStep"
        :mobile-button-width="268"
        confirm-button-text="Next"
      />
    </form>
  </wrapper-with-close-btn>
</template>

<script>
import '@/assets/styles/service-request.scss';
import {mapState, mapMutations, mapGetters} from 'vuex';
import DeviceIcon from '@/components/shared/DeviceIcon';
import StepNavigationButtons from '@/components/shared/StepNavigationButtons';

export default {
  components: {
    DeviceIcon,
    StepNavigationButtons,
  },
  data() {
    return {
      loading: true,
      description: '',
      time: '',
    };
  },
  computed: {
    ...mapGetters('requestService', ['deviceForTitle']),
    ...mapState('deviceInformation', ['productLineName']),
    ...mapState('user', ['contractDetails']),
    disableNextStep() {
      return !this.description.trim() || !this.time || this.loading;
    },
    contractStartDate() {
      const startDate = this?.contractDetails?.startDate;
      if (startDate) {
        const dateArr = startDate.split('/');
        return `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}`;
      }
      return null;
    },
  },
  methods: {
    ...mapMutations('requestService', ['SET_DETAILS']),
    next() {
      const {description, time} = this;
      const details = {description, time};

      this.SET_DETAILS(details);
      this.$emit('next', details);
    },
  },
  async beforeCreate() {
    const check = await this.$store.dispatch('requestService/CHECK_ISSUES_UNDER_PLAN');
    const checkCovered =
      check?.data?.response?.checkIssuesUnderPlanResponse?.issues[0]?.coveredUnderPlan;
    if (checkCovered === 'false' || checkCovered === false) {
      this.$emit('uncovered');
    }
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
.details__field {
  @include mf($gridMDBreakpoint) {
    max-width: 80%;
  }
}
.details__label {
  margin-top: 26px;
}
</style>

<style lang="scss">
.hs-datepicker__value {
  border-color: #757575 !important;
}
</style>
