<template>
  <wrapper-with-close-btn :preventNavigation="true" class="service-request" @back="$emit('close')">
    <hs-heading
      v-show="deviceForTitle"
      :title="`We Need More Info for ${deviceForTitle}`"
      class="service-request__title"
    />
    <div class="service-request__row">
      <div class="service-request__col service-request__col--icon">
        <DeviceIcon
          v-show="productLineName"
          :icon="productLineName"
          class="service-request__device-icon"
        />
      </div>
      <div v-if="!isTelevision" class="service-request__col">
        <div class="hs-label service-request__label--address">
          Is the device currently located at the following home address?
        </div>
        <div class="service-request__address" v-if="selectedAddressText">
          {{ selectedAddressText }}
          <br />
          {{ selectedAddressLandmark }}
        </div>
        <hs-radio
          id="address_home"
          value="home"
          name="address_options"
          class="service-request__radio"
          @change="changeAddressType(false)"
        >
          <div class="service-request__select">Device is at home address</div>
        </hs-radio>
        <div class="hs-label service-request__label service-request__label--address">
          Or are you travelling with your device?
        </div>
        <div class="service-request__address">
          Please note: the address you provide above (either home or other) will be the confirmed
          address for us to provide you with service. It cannot be changed at any time.
        </div>
        <hs-radio
          id="address_travel"
          value="travel"
          name="address_options"
          class="service-request__radio"
          @change="changeAddressType(true)"
        >
          <div class="service-request__select">I’m travelling with device</div>
        </hs-radio>
      </div>
    </div>

    <hs-popup
      v-if="isInvalidAddressPopupVisible"
      aria-labelledby="invalid-home-address-title"
      aria-describedby="invalid-home-address-desc"
      confirm-button-text="Ok"
      @hide="isInvalidAddressPopupVisible = false"
      @confirm="isInvalidAddressPopupVisible = false"
    >
      <h1 class="hs-popup__title" id="invalid-home-address-title">
        Looks like your home address is incorrect. Please call us to correct it.
      </h1>

      <div class="hs-popup__description">
        <p id="invalid-home-address-desc">
          We need your correct home address to provide service. Please call HomeServe at
          <a :href="phones.contactCenterTel">{{ phones.contactCenter }}</a> to change it so we can
          proceed.
        </p>
      </div>
    </hs-popup>

    <StepNavigationButtons
      v-if="!isTelevision"
      confirm-button-text="Next"
      back-button-text="Back"
      :mobile-button-width="308"
      :disabled="fetchGeoLocationInProgress || !isAddressSelected"
      :disabled-back="fetchGeoLocationInProgress"
      @back="back"
      @confirm="proceed(isTravelAddressSelected)"
    />
  </wrapper-with-close-btn>
</template>

<script>
import '@/assets/styles/service-request.scss';
import DeviceIcon from '@/components/shared/DeviceIcon';
import StepNavigationButtons from '@/components/shared/StepNavigationButtons';
import phones from '@/constants/phones';
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
import {television} from '@/constants/device_product_lines';

export default {
  components: {
    DeviceIcon,
    StepNavigationButtons,
  },
  props: {
    issueTitle: String,
    issueIcon: String,
    issueCategory: String,
  },
  data: () => ({
    isAddressSelected: false,
    isTravelAddressSelected: false,
    isInvalidAddressPopupVisible: false,
  }),
  computed: {
    ...mapState('deviceInformation', ['productLine', 'productLineName']),
    ...mapState('requestService', ['fetchGeoLocationInProgress']),
    ...mapGetters('requestService', [
      'selectedAddressLandmark',
      'selectedAddressText',
      'deviceForTitle',
    ]),
    phones: () => phones,
    isTelevision() {
      return this.productLine === television;
    },
  },
  methods: {
    ...mapMutations('requestService', ['SET_USE_CUSTOM_ADDRESS']),
    ...mapActions('requestService', ['FETCH_GEO_LOCATION']),
    changeAddressType(isTravelAddress) {
      this.isTravelAddressSelected = isTravelAddress;
      this.isAddressSelected = true;
    },
    back() {
      this.$emit('back');
    },
    async proceed(useCustomAddress) {
      this.SET_USE_CUSTOM_ADDRESS(useCustomAddress);

      if (!useCustomAddress) {
        const geoLocationFetched = await this.FETCH_GEO_LOCATION();

        if (!geoLocationFetched) {
          this.isInvalidAddressPopupVisible = true;
        } else {
          this.$emit('next', !useCustomAddress);
        }
      } else {
        this.$emit('next', !useCustomAddress);
      }
    },
  },
  created() {
    this.SET_USE_CUSTOM_ADDRESS(false);

    if (this.isTelevision) {
      this.proceed(false);
    }
  },
};
</script>
