<template>
  <wrapper-with-close-btn :preventNavigation="true" class="service-request" @back="$emit('close')">
    <hs-heading
      v-show="deviceForTitle"
      :title="`Select An Option for ${deviceForTitle}`"
      class="service-request__title"
    />
    <div class="service-request__row">
      <div class="service-request__col service-request__col--icon">
        <DeviceIcon
          v-show="productLineName"
          :icon="productLineName"
          class="service-request__device-icon"
        />
      </div>
      <div v-if="availableOptions && availableOptions.length" class="service-request__col">
        <div class="service-request__title">
          <template v-if="availableOptions.length > 1">
            To resolve your issue, we offer these options.
            <br />
            Please select one from list below
          </template>
          <template v-else-if="availableOptions.length === 1 && !serviceAvailabilityInProgress">
            To resolve your issue, we offer a {{ firstOptionTitle }} option.
            <br />
            Please proceed if you agree
          </template>
        </div>

        <div class="service-request__options">
          <template v-if="availableOptions.length > 1">
            <div
              v-for="option in availableOptions"
              :key="option"
              class="service-request__option service-request__option--radio"
            >
              <hs-radio
                :id="`claimOptions_${option}`"
                :value="option"
                name="claim_options"
                @change="setOption"
              >
                <span class="service-request__title">{{ getTitle(option) }}</span>
                {{ getDescription(option) }}
              </hs-radio>
            </div>
          </template>
          <template v-else-if="availableOptions.length === 1 && !serviceAvailabilityInProgress">
            <div class="service-request__option">
              <b class="service-request__title">{{ getTitle(firstOption) }}</b>
              <p class="service-request__option-description">
                {{ getDescription(firstOption) }}
              </p>
            </div>
          </template>
        </div>
      </div>
      <div class="service-request__col" v-else>
        <div class="service-request__title" v-if="error">{{ error }}</div>
      </div>
    </div>

    <template v-if="!serviceAvailabilityInProgress">
      <StepNavigationButtons
        v-if="availableOptions.length > 1"
        :has-back-button="false"
        :mobile-button-width="268"
        :disabled="confirmButtonDisabled"
        :confirm-button-text="confirmButtonText"
        @confirm="confirm"
      />
      <StepNavigationButtons
        v-else-if="availableOptions.length === 1"
        :mobile-button-width="268"
        :back-button-text="`Decline ${firstOptionTitle}`"
        :confirm-button-text="singleOptionConfirmButtonText"
        :disabled="disableButtons"
        :disabled-back="disableButtons"
        @back="goToMyDevices"
        @confirm="confirm"
      />
    </template>
  </wrapper-with-close-btn>
</template>

<script>
import '@/assets/styles/service-request.scss';
import DeviceIcon from '@/components/shared/DeviceIcon';
import claimOptions from '@/constants/claim-options';
import StepNavigationButtons from '@/components/shared/StepNavigationButtons';
import {mapState, mapActions, mapGetters} from 'vuex';

export default {
  components: {
    DeviceIcon,
    StepNavigationButtons,
  },
  props: {
    issueTitle: String,
    issueIcon: String,
    issueCategory: String,
  },
  data() {
    return {
      error: '',
      optionsList: claimOptions,
      selectedOption: '',
      loading: true,
      serviceAvailabilityInProgress: false,
      disableButtons: false,
    };
  },
  computed: {
    ...mapState('deviceInformation', ['productLineName']),
    ...mapState('user', ['contractDetails']),
    ...mapGetters('requestService', ['availableOptions', 'buyoutCost', 'deviceForTitle']),
    firstOption() {
      return this.availableOptions[0];
    },
    firstOptionData() {
      const {firstOption, optionsList} = this;
      return optionsList[firstOption];
    },
    firstOptionTitle() {
      return this.getTitle(this.firstOption);
    },
    hasOptions() {
      const {availableOptions} = this;
      return availableOptions && availableOptions.length;
    },
    confirmButtonText() {
      const {hasOptions} = this;
      return hasOptions ? 'Next' : 'OK';
    },
    confirmButtonDisabled() {
      const {hasOptions, disableNext} = this;
      let disabled = false;
      if (hasOptions) {
        disabled = disableNext;
      }
      return disabled;
    },
    disableNext() {
      const {selectedOption, disableButtons, loading} = this;
      return !selectedOption || disableButtons || loading;
    },
    singleOptionConfirmButtonText() {
      const {firstOption, firstOptionTitle, firstOptionData} = this;
      let title = `Proceed with ${firstOptionTitle}`;
      if (firstOption === 'Reimbursement' && !this.buyoutCost) {
        title = firstOptionData.singleOptionConfirmButtonText;
      }

      return title;
    },
  },
  methods: {
    ...mapActions('requestService', ['INIT_MASTER_CLAIM']),
    getTitle(id) {
      return this.optionsList[id]?.title;
    },
    getDescription(id) {
      let description = this.optionsList[id]?.description;
      if (typeof description === 'function') {
        description = description(this);
      }
      // buyout amount unknown
      if (!this.buyoutCost) {
        description =
          'Time is needed for us to determine the fair market value of your device. Once available, we will notify you via emails/SMS for you to review and accept the Buyout option.';
      }
      return description;
    },
    setOption(e) {
      this.selectedOption = e.value;
    },
    closeClaim() {
      const {query} = this.$route;
      this.$router.push({
        name: 'MyDevices',
        query,
      });
    },
    goToMyDevices() {
      const {query} = this.$route;
      delete query.case;
      this.$router.push({
        name: 'MyDevices',
        query,
      });
    },
    async onReimbursementSelected() {
      const {buyoutCost} = this;
      const serviceTypeCode = 'CLAIM_BUYOUT_WITHOUT_DEFECTIVE';
      const {customerRef, contractRef} = this.$route.query;
      const inventoryEquipmentId = this.$route.params.id;

      const hasBuyoutCost = !!buyoutCost;

      this.disableButtons = true;
      const contract = this.contractDetails || {};
      const PartnerCode = contract?.partnerCode;

      const {id} = await this.INIT_MASTER_CLAIM({
        disableServify: true,
        raiseClaim: !hasBuyoutCost,
        serviceTypeCode,
        inventoryEquipmentId,
        encryptedRequestParams: {
          customerRef,
          contractRef,
        },
        PartnerCode,
      });

      this.disableButtons = false;

      if (!hasBuyoutCost) {
        const {query} = this.$route;
        this.$router.push({
          name: 'MyDevices',
          query,
        });
      }

      return hasBuyoutCost;
    },
    async confirm() {
      const {hasOptions} = this;
      if (hasOptions) {
        const selectedOption = this.selectedOption || this.availableOptions[0];
        const selectedOptionHook = this[`on${selectedOption}Selected`];
        if (selectedOptionHook) {
          const emitNext = await selectedOptionHook.call(this);
          if (emitNext) {
            if (selectedOption === 'Reimbursement') {
              // Move to confirm buyout page.
              this.confirmReimbursement(true);
            } else {
              this.$emit('next', selectedOption);
            }
          }
        } else {
          this.$emit('next', selectedOption);
        }
      } else {
        this.closeClaim();
      }
    },
    confirmReimbursement(multipleOptions) {
      const {query, params} = this.$route;
      const {id} = params;
      const type = multipleOptions ? 'multiple' : 'single';
      if (id) {
        this.$router.push({
          name: 'ConfirmAClaim',
          params: {
            id,
            type,
          },
          query,
        });
      } else {
        this.$router.push({
          name: 'MyDevices',
          query,
        });
      }
    },
  },
  async beforeCreate() {
    const {customerRef} = this.$route.query;
    const decryptedParams = await this.$store.dispatch('user/DECRYPT_PARAMS', {
      customerRef,
    });
    const request = await this.$store.dispatch(
      'requestService/CLAIM_ADJUDICATION',
      decryptedParams.customerRef
    );
    this.loading = false;
    const respOptions =
      request?.data?.response?.claimAdjudicationResponse?.allowedModes?.allowedMode;
    if (!respOptions) {
      this.error =
        request?.data?.response?.claimAdjudicationResponse?.errorDetails?.errorDetail?.errorDescription;
    } else {
      this.serviceAvailabilityInProgress = true;

      const getAvailabilityRequestOptions = ['Repair', 'Replacement'];
      const serviceRequestModes = getAvailabilityRequestOptions.filter((mode) =>
        respOptions.includes(mode)
      );

      if (
        serviceRequestModes.length === 0 &&
        this.availableOptions.includes('Reimbursement') &&
        !!this.buyoutCost
      ) {
        // Select buyout as a single option immediately
        // Create master claim
        await this.onReimbursementSelected();
        this.confirmReimbursement(false);
      }

      try {
        await Promise.all(
          serviceRequestModes.map((mode) =>
            this.$store.dispatch('requestService/GET_AVAILABILITY', {mode})
          )
        );
      } finally {
        this.serviceAvailabilityInProgress = false;
      }
    }
  },
};
</script>
