import Vue from 'vue';

export const SPECIAL_CHARACTER_REG_EXP = /^[a-z-A-Z\ \d#-.`\/'()]+$/;
export const ZIP_CODE_REG_EXP = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const HOUSE_NUMBER_REG_EXP = /^\d+[\/-a-zA -Z'"`]*\d*[-a-zA-Z'"`]*$/;

export const SNIP_SPECIAL_CHARACTER_REG_EXP = /[@£^=\[\]{}\\|<>?!]/;

export const validators = {
  notEmpty: (string) => ({
    isValid: string?.length > 0,
  }),

  noAmpersand: (string) => {
    const isValid = !string.includes('&');
    const character = isValid ? null : '&';
    return {isValid, character};
  },

  noSpecialCharacters: (string) => {
    let character = null;

    const isValid = SPECIAL_CHARACTER_REG_EXP.test(string);

    if (!isValid) {
      const matches = string.match(SNIP_SPECIAL_CHARACTER_REG_EXP);
      if (matches) {
        character = matches[0][0];
      }
    }

    return {
      isValid: !character,
      character,
    };
  },

  zipCode: (zipCode) => {
    const isValid = ZIP_CODE_REG_EXP.test(zipCode);
    return {isValid};
  },

  houseNumber: (houseNumber) => {
    const isValid = HOUSE_NUMBER_REG_EXP.test(houseNumber);
    return {isValid};
  },
};

const invalidErrorMessage = ({name}) => `Invalid ${name}`;

const noSpecialCharactersErrorMessage = ({name}, {character}) => {
  return character
    ? `Special characters such as ${character} are not allowed in ${name}`
    : invalidErrorMessage({name});
};

export const validationErrorMessages = {
  notEmpty: ({name}) => `${name} is required`,
  invalid: invalidErrorMessage,
  noSpecialCharacters: noSpecialCharactersErrorMessage,
  noAmpersand: noSpecialCharactersErrorMessage,
};

export const getValidator = (name, rulesList) => {
  return (input) => {
    let error = null;

    for (const validatorName of rulesList) {
      const validationResult = validators[validatorName](input);
      if (!validationResult.isValid) {
        const getError = validationErrorMessages[validatorName] || validationErrorMessages.invalid;
        error = getError({name, input}, validationResult);
        break;
      }
    }

    return error;
  };
};

export const getFormValidator = (fieldValidatorsList) => {
  const fieldsValidators = {};

  for (const [validatorName, {name, rules}] of Object.entries(fieldValidatorsList)) {
    fieldsValidators[validatorName] = getValidator(name, rules);
  }

  return function (fieldName) {
    let isValid;

    const validateField = (fieldName) => {
      let error = null;
      const validator = fieldsValidators[fieldName];

      if (validator) {
        error = validator(this[fieldName]);
        Vue.set(this.errors, fieldName, error);
      }

      return error;
    };

    if (fieldName) {
      isValid = !validateField(fieldName);
    } else {
      const errors = [];

      for (const fieldName of Object.keys(fieldsValidators)) {
        errors.push(validateField(fieldName));
      }

      isValid = errors.every((error) => !error);
    }

    return isValid;
  };
};

export default getFormValidator;
