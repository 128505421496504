export default {
  CLAIM_CARRYIN: {
    title: 'Carry-In Service',
    description:
      'Bring your device and drop it off at an authorized service center. We will provide you with the nearest service center location(s). You will be asked to schedule a date for carry-in service. You will be asked to come and collect your device after service is completed.',
  },
  CLAIM_DROPOFF: {
    title: 'Depot Service',
    description:
      'We will send you a prepaid label and shipping instructions to mail your device to an authorized service center. Whenever applicable, we will arrange for your device to be returned to you.',
  },
  Replacement: {
    title: 'Replacement',
    description:
      'We will replace your device with one of the same brand, model and/or color whenever possible. If this is not possible, we will replace your device with one of equal or similar features and functionality which may mean a different brand, model or color from your original Device.',
  },
  Reimbursement: {
    title: 'Buyout',
    description:
      'We will send you a payment based on the fair market value of your device at the time the Service Call was made, up to the benefit limit amount minus the Service Call fee. Once you accept this buyout option, payment processing and delivery will take up to 30 days.',
    singleOptionConfirmButtonText: 'Agree and Wait for Update',
  },
  Repair: {
    title: 'Repair',
    description: 'Choose option to repare',
  },
  CLAIM_SELF_REPAIR: {
    title: 'Reimbursement',
    description:
      'Please take your device to a service center of your choice. You will be required to directly pay the service center. For reimbursement, you will need to provide us with copies of your paid receipt(s), and we will reimburse you up the benefit limit, less any applicable Service Call fee.',
  },
  CLAIM_ONSITE: {
    title: 'Home Service',
    description:
      'We will schedule a diagnostic call with one of our technicians before coming to your home. Should an in-home visit be needed, it will be scheduled to occur during regular business hours, Monday through Friday (except holidays). Remember that a technician must have safe and clear access to your device and safe working conditions; and an adult (18 years or older) must be present.',
  },
};
