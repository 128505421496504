<template>
  <wrapper-with-close-btn :preventNavigation="true" class="service-request" @back="$emit('close')">
    <hs-heading
      v-show="deviceForTitle"
      :title="`Provide Us With Details for ${deviceForTitle}`"
      class="service-request__title"
    />
    <div class="service-request__row">
      <div class="service-request__col service-request__col--icon">
        <DeviceIcon
          v-show="productLineName"
          :icon="productLineName"
          class="service-request__device-icon"
        />
      </div>
      <div class="service-request__col">
        <div class="service-request__title">Please select one:</div>
        <div class="service-request__options">
          <div
            class="service-request__option"
            v-for="category in categories"
            :key="category.issueCode"
          >
            <hs-radio
              name="issueType"
              @change="setType({type: category.issueCategory, code: category.issueCategoryCode})"
            >
              {{ category.issueCategory }}
            </hs-radio>
          </div>
        </div>
      </div>
    </div>
    <StepNavigationButtons
      :has-back-button="false"
      confirm-button-text="Next"
      :disabled="disableNextStep"
      :mobile-button-width="268"
      @confirm="selectIssueCategory"
    />
  </wrapper-with-close-btn>
</template>

<script>
import '@/assets/styles/service-request.scss';
import DeviceIcon from '@/components/shared/DeviceIcon';
import StepNavigationButtons from '@/components/shared/StepNavigationButtons';
import {mapState, mapGetters} from 'vuex';

export default {
  components: {
    DeviceIcon,
    StepNavigationButtons,
  },
  data() {
    return {
      type: '',
      typeCode: '',
      issues: [],
      categories: [],
    };
  },
  computed: {
    ...mapGetters('requestService', ['deviceForTitle']),
    ...mapState('deviceInformation', [
      'productLine',
      'productLineName',
      'make',
      'model',
      'serialNumber',
    ]),
    disableNextStep() {
      return !this.type;
    },
  },
  methods: {
    setType({type, code}) {
      this.type = type;
      this.typeCode = code;
    },
    selectIssueCategory() {
      const {type, typeCode} = this;
      this.$store.dispatch('requestService/SET_ISSUE_CATEGORY', {
        type,
        typeCode,
        issues: this.issues.filter((iss) => iss.issueCategory === type),
      });
      this.$emit('next', type);
    },
  },
  async beforeCreate() {
    const {id} = this.$route.params;
    const {customerRef} = this.$route.query;

    const resp = await this.$store.dispatch('user/DECRYPT_PARAMS', {
      customerRef,
    });

    await this.$store.dispatch('deviceInformation/GET_DEVICE_INFO', {
      id,
      customerRef: resp?.customerRef,
    });

    const issues = await this.$store.dispatch('requestService/GET_ISSUE_LIST', {
      productSubCategoryCode: this.productLine,
      brandCode: this.make,
      productCode: this.model,
      serialNumber: this.serialNumber,
      productLineName: this.productLineName,
    });
    this.issues = issues.data.response.getIssueListResponse.issues;
    this.categories =
      this.issues?.reduce((a, d) => {
        if (!a.filter((iss) => iss['issueCategoryCode'] === d['issueCategoryCode']).length) {
          a.push({
            issueCategory: d['issueCategory'],
            issueCategoryCode: d['issueCategoryCode'],
          });
        }
        return a;
      }, []) || [];
  },
};
</script>
