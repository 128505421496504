<template>
  <wrapper-with-close-btn :preventNavigation="true" class="service-request" @back="$emit('close')">
    <hs-heading
      v-show="deviceForTitle"
      :title="`We Need More Info for ${deviceForTitle}`"
      class="service-request__title"
    />
    <div class="service-request__row">
      <div class="service-request__col service-request__col--icon">
        <DeviceIcon
          v-show="productLineName"
          :icon="productLineName"
          class="service-request__device-icon"
        />
      </div>
      <form @submit.stop.prevent="finish" class="add-device__fieldset">
        <div class="service-request__col">
          <p class="service-request__address-message-title">
            <b>Please enter your current travel address</b>
          </p>
          <p class="service-request__text-offset-bottom">
            If you are travelling with your device in the contiguous Unites States (excluding
            Alaska, Hawaii and other US territories,) please enter your current travel address.
          </p>
          <div class="service-request__columns service-request__fields">
            <div class="service-request__column service-request__column--6">
              <div class="service-request__street-and-house-number-container">
                <div class="service-request__house-number-container">
                  <hs-label class="service-request__label" for="houseNumber">House Number</hs-label>
                  <hs-input
                    :value="houseNo"
                    placeholder="№"
                    id="houseNumber"
                    @input="(value) => setAddressAttribute({field: 'houseNo', value})"
                  />
                </div>

                <div class="service-request__street-container">
                  <hs-label class="service-request__label" for="streetName"
                    >Street Address</hs-label
                  >
                  <hs-input
                    :value="streetName"
                    id="streetName"
                    placeholder="Please enter your street"
                    @input="(value) => setAddressAttribute({field: 'streetName', value})"
                  />
                </div>
              </div>

              <div class="service-request__street-and-house-number-errors-container">
                <p v-if="errors.houseNo">
                  {{ errors.houseNo }}
                </p>
                <p v-if="errors.streetName">
                  {{ errors.streetName }}
                </p>
              </div>

              <hs-label class="service-request__label" for="city">City</hs-label>
              <hs-input
                :value="city"
                id="city"
                placeholder="Please enter your city"
                @input="(value) => setAddressAttribute({field: 'city', value})"
                :error="errors.city"
              />

              <hs-label class="service-request__label" for="zip">Zip Code</hs-label>
              <hs-input
                :value="zip"
                id="zip"
                placeholder="Please enter your zip code"
                @input="(value) => setAddressAttribute({field: 'zip', value})"
                :error="errors.zip"
              />
            </div>
            <div class="service-request__column service-request__column--6">
              <hs-label class="service-request__label" for="flatNo"
                >Apartment, Suite, etc. (optional)</hs-label
              >
              <hs-input
                :value="flatNo"
                placeholder="Please enter your apartment, suite, etc"
                id="flatNo"
                @input="(value) => setAddressAttribute({field: 'flatNo', value})"
                :error="errors.flatNo"
              />

              <hs-label class="service-request__label" for="state">State</hs-label>
              <hs-select
                :options="states"
                :value="state"
                id="state"
                placeholder="Please select your state"
                @input="(value) => SET_ADDRESS_ATTRIBUTE({field: 'state', value})"
              />
            </div>
          </div>
          <!--  -->
        </div>
        <StepNavigationButtons
          :has-back-button="false"
          :mobile-button-width="258"
          :disabled="disableNext"
          confirm-button-text="Next"
        />
      </form>
    </div>

    <hs-popup
      v-if="isInvalidAddressPopupVisible"
      aria-labelledby="invalid-travel-address-title"
      aria-describedby="invalid-travel-address-desc"
      confirm-button-text="Edit Travel Address"
      @hide="isInvalidAddressPopupVisible = false"
      @confirm="isInvalidAddressPopupVisible = false"
    >
      <h1 class="hs-popup__title" id="invalid-travel-address-title">
        Looks like your travel address is incorrect. Please correct it in order to proceed.
      </h1>

      <div class="hs-popup__description">
        <p id="invalid-travel-address-desc">
          We need a valid travel address in order to provide you service and to proceed. Please be
          sure to include your apartment number, if applicable.
        </p>
      </div>
    </hs-popup>
  </wrapper-with-close-btn>
</template>

<script>
import '@/assets/styles/service-request.scss';
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex';
import DeviceIcon from '@/components/shared/DeviceIcon';
import StepNavigationButtons from '@/components/shared/StepNavigationButtons';
import USStates from '@/constants/us_states';
import getFormValidator from '@/utils/getFormValidator';

export default {
  components: {
    DeviceIcon,
    StepNavigationButtons,
  },
  data: () => ({
    errors: {},
    zipCodeMatchesState: true,
    isInvalidAddressPopupVisible: false,
  }),
  computed: {
    ...mapGetters('requestService', ['deviceForTitle']),
    ...mapState('deviceInformation', ['productLineName']),
    ...mapState('requestService', {
      state: (state) => state?.address?.state,
      streetName: (state) => state?.address?.streetName,
      city: (state) => state?.address?.city,
      zip: (state) => state?.address?.zip,
      flatNo: (state) => state?.address?.flatNo,
      houseNo: (state) => state?.address?.houseNo,
    }),
    ...mapState('requestService', ['fetchGeoLocationInProgress']),
    ...mapState('user', ['getTerritoryInProgress']),
    disableNext() {
      const {getTerritoryInProgress, fetchGeoLocationInProgress, errors} = this;
      const requiredFields = ['houseNo', 'streetName', 'city', 'zip', 'state'];

      return (
        requiredFields.some((field) => !this[field]?.trim()) ||
        Object.values(errors).some((error) => error) ||
        getTerritoryInProgress ||
        fetchGeoLocationInProgress
      );
    },
    states() {
      return USStates;
    },
  },
  watch: {
    zipCodeMatchesState(newValue) {
      const errorMessage = newValue ? null : 'Zip Code does not match state';
      this.$set(this.errors, 'zip', errorMessage);
    },
    state(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        if (!this.zipCodeMatchesState) {
          this.zipCodeMatchesState = true;
        }
      }
    },
  },
  methods: {
    ...mapMutations('requestService', ['SET_ADDRESS_ATTRIBUTE']),
    ...mapActions('requestService', ['FETCH_GEO_LOCATION']),
    ...mapActions('user', ['GET_TERRITORY']),

    validate: getFormValidator({
      streetName: {
        name: 'Street Address',
        rules: ['noSpecialCharacters'],
      },
      houseNo: {
        name: 'House Number',
        rules: ['noSpecialCharacters', 'houseNumber'],
      },
      city: {
        name: 'City',
        rules: ['noAmpersand'],
      },
      zip: {
        name: 'Zip Code',
        rules: ['noSpecialCharacters', 'zipCode'],
      },
      flatNo: {
        name: 'Apartment',
        rules: ['noSpecialCharacters'],
      },
    }),

    async validateZipCodeState() {
      let isValid = true;

      const lookup = await this.GET_TERRITORY(this.zip);
      if (lookup) {
        const {stateName} = lookup;
        if (stateName.toLowerCase() !== this.state.toLowerCase()) {
          isValid = false;
        }
      }

      this.zipCodeMatchesState = isValid;

      return isValid;
    },

    async finish() {
      const isValidZipCode = await this.validateZipCodeState();

      if (isValidZipCode) {
        const geoLocationFetched = await this.FETCH_GEO_LOCATION();
        if (!geoLocationFetched) {
          this.isInvalidAddressPopupVisible = true;
        } else {
          this.$emit('next');
        }
      }
    },

    setAddressAttribute(payload) {
      this.SET_ADDRESS_ATTRIBUTE(payload);

      this.validate(payload.field);
    },
  },
};
</script>

<style scoped lang="scss">
.service-request {
  padding-bottom: 120px;

  @include mf($gridMDBreakpoint) {
    padding-bottom: 0;
  }

  &__street-and-house-number-container {
    display: flex;
    flex-grow: 1;
    gap: 12px;
    align-items: flex-end;
  }

  &__street-and-house-number-errors-container {
    margin-top: 10px;
    color: #e62419;
    font-weight: 700;
    font-size: 16px;
  }

  &__house-number-container {
    flex-basis: 120px;
  }

  &__street-container {
    flex-grow: 1;
  }

  &__address-message-title {
    margin-bottom: 12px;
    font-size: 16px;

    @include mf($gridMDBreakpoint) {
      font-size: 20px; // According OSS-264 #15.
    }
  }
  &__columns {
    @include mf($gridMDBreakpoint) {
      display: flex;
      margin: 35px -50px 0;
    }
  }
  &__column {
    padding-bottom: 35px;

    @include mf($gridMDBreakpoint) {
      flex: 1;
      padding: 0 50px;
      &--6 {
        flex: 1 0 50%;
        max-width: 50%;
      }
    }
  }
  &__label {
    margin-top: 35px;
    &:first-child {
      margin-top: 0;
    }
  }
  &__field {
    margin-top: 12px;
  }
  &__text-offset-bottom {
    padding-bottom: 20px;

    @include mf($gridMDBreakpoint) {
      padding: 0;
    }
  }
}
</style>
