import toHtmlForProps from '@/utils/toHtmlForProps';

const data = {
  claim: {
    title: 'Are you sure you want to leave this page?',
    text: 'By quitting this process, all information you have already provided regarding your device issue will be discarded. Are you sure you want to proceed?',
    labelledBy: 'claim-initiation-cancellation',
    buttons: ['Get back', 'Leave page'],
  },
  issueNotCovered: {
    title: 'We are unable to make a determination for your service request.',
    text: (_, {phones}) =>
      `Please call HomeServe at ${phones.contactCenter} to get assistance from a call center agent.`,
    labelledBy: 'selected-issue-not-covered',
    buttons: ['OK'],
  },
};

toHtmlForProps(data, 'text');
export default data;
